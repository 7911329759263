import type { SerializeFrom } from '@remix-run/node';
import type { loader as RootLoader } from '~/root';
import type { loader as AdminLoader } from '~/routes/admin+/_layout';

import { useRouteLoaderData as useRemixRouteLoaderData } from '@remix-run/react';

type Loaders = {
  root: typeof RootLoader;
  'routes/admin+/_layout': typeof AdminLoader;
};

export function useTypedRouteLoaderData<T extends keyof Loaders>(routeId: T) {
  return useRemixRouteLoaderData(routeId) as SerializeFrom<Loaders[T]>;
}
